@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap");

body {
  height: 100%;
  font-family: "Inter", sans-serif;
  /* background-color: aqua !important; */
}

.app-container {
  min-height: 100vh;
}

.form-inline label {
  margin-right: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #d9d9d9 inset !important;
}

/* css to handle header & footer in multiple pages */
/* @media print {
  header,
  footer {
    position: fixed;
    left: 0;
    right: 0;
  }

  header {
    top: 0;
  }

  footer {
    bottom: 0;
  }
} */

@media screen {
  .hide-print {
    visibility: hidden;
  }
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.table-button {
  background-color: #17a2b8;
  color: #fff; /* Text color */
  padding: 2px 6px; /* Adjust the padding to your preference */
  border: none;
  border-radius: 5px; /* Rounded corners */
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); Box shadow properties/ */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Add smooth transitions */
}

/* Hover effect to change background color and box shadow */
.table-button:hover {
  background-color: #456799; /* Change the color on hover */
  /* box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3); Adjust the box shadow on hover */
}

.modalCrossBtn {
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.nav-link.active {
  background-color: #022e3f;
  border-radius: 6px;
}
